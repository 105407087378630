import logo from './logo.svg';
import './App.css';
import Todo from './Componet/Todo';

function App() {
  return (
    <div className="App">
      
      <div><Todo/></div>
      <div/>
    </div>
  );
}

export default App;
