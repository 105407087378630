import React, { useState } from 'react';
import './todo.css';

// Navbar Component
const Navbar = () => {
  return (
    <nav className="navbar">
      <h1>Todo App</h1>
    </nav>
  );
};

// TodoItem Component
const TodoItem = ({ todo, toggleComplete, handleDelete }) => {
  return (
    <div className={`todoItem ${todo.completed ? 'completed' : ''}`}>
      <input 
        type="checkbox" 
        checked={todo.completed} 
        onChange={() => toggleComplete(todo.id)} 
      />
      <span className="text">{todo.text}</span>
      <button className="deleteButton" onClick={() => handleDelete(todo.id)}>
        Delete
      </button>
    </div>
  );
};

// TodoForm Component
const TodoForm = ({ addTodo }) => {
  const [todoText, setTodoText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (todoText.trim()) {
      addTodo(todoText);
      setTodoText('');
    }
  };

  return (
    <form className="todoForm" onSubmit={handleSubmit}>
      <input 
        type="text" 
        value={todoText} 
        onChange={(e) => setTodoText(e.target.value)} 
        placeholder="Add a new task" 
      />
      <button type="submit">Add</button>
    </form>
  );
};

// App Component
const App = () => {
  const [todos, setTodos] = useState([]);

  // Add a new Todo
  const addTodo = (text) => {
    const newTodo = {
      id: todos.length + 1,
      text: text,
      completed: false,
    };
    setTodos([...todos, newTodo]);
  };

  // Toggle completion of a todo
  const toggleComplete = (id) => {
    setTodos(
      todos.map((todo) => 
        todo.id === id ? { ...todo, completed: !todo.completed } : todo
      )
    );
  };

  // Delete a todo
  const handleDelete = (id) => {
    setTodos(todos.filter((todo) => todo.id !== id));
  };

  return (
    <div className="app">
      <Navbar />
      <TodoForm addTodo={addTodo} />
      {todos && <div className="todoList">
        {todos?.map((todo) => (
          <TodoItem 
            key={todo.id} 
            todo={todo} 
            toggleComplete={toggleComplete} 
            handleDelete={handleDelete} 
          />
        ))}
      </div>}
    </div>
  );
};

export default App;